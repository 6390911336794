<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          lazy
          close-on-content-click
          transition="scale-transition"
          full-width
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            min="2018-01"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-spacer />
        <black-pins v-model="pin" />
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :total-items="31"
        class="px-1 pb-1"
        item-key="date"
        hide-actions
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <td class="text-xs-center">
            {{ props.item.date }}
          </td>
          <td class="text-xs-center">
            {{ props.item.new }}
          </td>
          <td class="text-xs-center">
            {{ props.item.mnp }}
          </td>
          <td class="text-xs-center">
            {{ props.item.cobp }}
          </td>
          <td class="text-xs-center">
            {{ props.item.cop }}
          </td>
          <td class="text-xs-center info">
            {{ props.item.new + props.item.mnp + props.item.cobp + props.item.cop }}
          </td>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template slot="footer">
          <tr>
            <td>&nbsp;</td>
            <td class="text-xs-center info--text">
              {{ totalNew }}
            </td>
            <td class="text-xs-center info--text">
              {{ totalMnp }}
            </td>
            <td class="text-xs-center info--text">
              {{ totalCobp }}
            </td>
            <td class="text-xs-center info--text">
              {{ totalCop }}
            </td>
            <td class="text-xs-center info">
              {{ total }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

const reportTitle = 'Black Pin Usages'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
    BlackPins: () => import(/* webpackChunkName: "blackPins" */ '@/components/BlackPins'),
  },
  data () {
    return {
      pin: { text: 'B89DB', value: 'B89DB' },
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local(2019, 9, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      breadcrumbs: [
        {
          text: 'Management Report', disabled: false, to: '/management/management_kits',
        },
        {
          text: 'Daily Excerpt', disabled: false, to: '/management/daily_view',
        },
        {
          text: 'Daily Black Pin Usage', disabled: true,
        },
      ],
      expand: true,
      headers: [
        {
          align: 'text-xs-center',
          sortable: false,
          text: 'Date',
          value: 'date',
        },
        {
          align: 'text-xs-center',
          sortable: false,
          text: 'New',
          value: 'new',
        },
        {
          align: 'text-xs-center',
          sortable: false,
          text: 'Mnp',
          value: 'mnp',
        },
        {
          align: 'text-xs-center',
          sortable: false,
          text: 'COBP',
          value: 'cobp',
        },
        {
          align: 'text-xs-center',
          sortable: false,
          text: 'Change Plan',
          value: 'cop',
        },
        {
          align: 'text-xs-center',
          sortable: false,
          text: 'Total',
          value: 'total',
        },
      ],
      items: [
      ],
      loading: false,
      maxDate: '',
      menu: false,
      monthOf: null,
      noDataText: null,
      noResultText: 'No data that fits that criteria.',
      title: reportTitle,
      totalNew: 0,
      totalMnp: 0,
      totalCobp: 0,
      totalCop: 0,
    }
  },
  computed: {
    total: function () {
      return this.totalNew + this.totalMnp + this.totalCobp + this.totalCop
    },
  },
  watch: {
    monthOf: function (val) {
      this.getBlackPinTransactions(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    getBlackPinTransactions (month) {
      const params = createGetParams({
        month: month,
        pin: this.pin.value,
      })
      this.loading = true
      this.$rest.get('getBlackPinUsages.php', params)
        .then(function (response) {
          this.items = response.data
          this.totalNew = this.totalMnp = this.totalCobp = this.totalCop = 0
          response.data.forEach(item => {
            this.totalNew += Number(item.new)
            this.totalMnp += Number(item.mnp)
            this.totalCobp += Number(item.cobp)
            this.totalCop += Number(item.cop)
          })
          this.noDataText = "There's nothing to show."
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message + ': ' + error.response.data
          this.loading = false
        })
    },
  },
}
</script>
